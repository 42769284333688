<template>
  <div class="wrapper">
  <div class="products">
    <div class="product" v-for="(product,index) in this.$root.$data.cart" :key="product.id">
      <div class="image">
        <img :src="'/images/products/'+product.image">
      </div>
      <div class="info">
        <h1>{{product.name}}</h1>
        <p>{{product.country}}</p>
      </div>
      <div class="price">
        <h2>{{product.price}}</h2>
        <h3>{{product.quantity}}</h3>
        <button @click="removeFromCart(product, index)" class="auto">Remove</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Cart',
  methods: {
    removeFromCart(product, index) {
      this.$root.$data.cart.splice(index, 1);
    }
  }
}
</script>


<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.products {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  width: 80%;
}

.product {
  margin: 10px;
  margin-top: 20px;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: solid black 2px;
  display: flex;
  flex-flow: row nowrap;
}

.product img {
  border: 2px solid #333;
  height: 250px;
  width: 200px;
  object-fit: cover;
}

.product .image {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.info {
  color: #000;
  padding: 10px 30px;
  height: 80px;
  width: 80%;
}

.info h1 {
  font-size: 30px;
}

.info h2 {
  font-size: 20px;
}

.info p {
  margin: 0px;
  font-size: 18px;
}


.price {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  height: 230px;
}

button {
  height: 50px;
  background: #000;
  color: white;
  border: none;
}

.auto {
  margin-left: auto;
}
</style>
